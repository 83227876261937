<template>
    <div class="offline__page">
        <div class="content">
            <div class="info">
                <offline-svg />
                <h2 class="text">
                    You are offline
                </h2>
            </div>
            <button class="btn" @click="refresh">Retry</button>
        </div>
    </div>
</template>

<script>
import OfflineSvg from '../components/icons/OfflineSvg.vue'

export default {
    components: {
        OfflineSvg
    },
    methods: {
        refresh() {
            this.$router.push('/');
            location.reload();
        },
    }
}

</script>

<style scoped lang="scss">
.offline__page {
    width: 100%;
    height: 100vh;
    background-color: #fff;

    @supports (-webkit-touch-callout: none) {
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
        padding-right: env(safe-area-inset-right);
        padding-left: env(safe-area-inset-left);
    }
}

.content {
    position: relative;
    height: 100%;
}

.info {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text {
    font-size: 15px;
    font-weight: 400;
    margin-top: 20px;
    letter-spacing: -0.5px;
}

.btn {
    border: none;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.5px;
    cursor: pointer;
    color: #464646;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: transparent;
    border-bottom: 1px solid #000;
}
</style>